/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import {
  useCallback,
  forwardRef,
  useState,
  useEffect,
} from '@web-stories-wp/react';
import type { ForwardedRef } from 'react';
import {
  TextArea as StyledTextArea,
  TextAreaProps,
} from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import { inputContainerStyleOverride } from '../panels/shared';

const TextArea = forwardRef(
  (
    {
      className,
      placeholder,
      value,
      disabled,
      rows = 2,
      onChange,
      onBlur,
      ...rest
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [currentValue, setCurrentValue] = useState(value);
    const handleChange = useCallback(
      (evt) => {
        onChange(evt);
      },
      [currentValue, onChange, value]
    );

    // If new value comes from the outer world, update the local, too.
    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleBlur = useCallback(
      (e) => {
        if (onBlur) {
          onBlur(e);
        } else {
          handleChange(e);
        }
      },
      [onBlur, handleChange]
    );

    return (
      <StyledTextArea
        placeholder={placeholder}
        value={currentValue}
        containerStyleOverride={inputContainerStyleOverride}
        onChange={(evt) => {
          // Change happens only once blurring to avoid repeated onChange calls for each letter change
          // unless the onBlur handler is already used to store the "internal" value
          if (onBlur) {
            handleChange(evt);
          }

          setCurrentValue(evt.target.value);
        }}
        onBlur={handleBlur}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default TextArea;
