/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { __, sprintf } from '@web-stories-wp/i18n';

/**
 * Internal dependencies
 */
import {
  CoverrAttribution,
  TenorAttribution,
  UnsplashAttribution,
} from './attribution';
import { ContentType, ProviderType } from './constants';

/** @typedef {import('react').React.Component} ReactComponent */

/**
 * @typedef ProviderConfiguration
 * @property {string} displayName The display name of the provider.
 * @property {?string} featureName An optional feature that must be turned on
 * for the provider tab to be displayed.
 * @property {?ContentType} contentTypeFilter Optional. The content type to filter by.
 * @property {boolean} supportsCategories Whether this provider supports
 * filtering media by category.
 * @property {boolean} requiresAuthorAttribution Whether this provider
 * requires showing author
 * attribution on each media element.
 * @property {function(): ReactComponent} attributionComponent A function that
 * constructs an attribution React Component for this provider.
 * @property {string} fetchMediaErrorMessage An error message to show if
 * fetching media from this provider fails.
 * @property {?string} fetchCategoriesErrorMessage An error message to show if
 * fetching categories from this provider fails. Only needs to be specified if
 * the `supportsCategories` is true.
 */

/**
 *
 * @type {Object.<string, ProviderConfiguration>}
 */
export const PROVIDERS = {
  [ProviderType.UNSPLASH]: {
    supportsCategories: false,
    requiresAuthorAttribution: true,
    attributionComponent: UnsplashAttribution,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Unsplash'
    ),
    fetchCategoriesErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading categories from %s', 'web-stories'),
      'Unsplash'
    ),
  },
  [ProviderType.COVERR]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    attributionComponent: CoverrAttribution,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Coverr'
    ),
    defaultPreviewWidth: 640,
  },
  [ProviderType.TENOR]: {
    contentTypeFilter: ContentType.GIF,
    supportsCategories: false,
    requiresAuthorAttribution: false,
    attributionComponent: TenorAttribution,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Tenor'
    ),
  },
  [ProviderType.GETTY]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'GettyImages'
    ),
  },
  [ProviderType.MIMIR]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Mimir'
    ),
    defaultPreviewWidth: 640,
  },
  [ProviderType.SLACK]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Slack'
    ),
    defaultPreviewWidth: 640,
  },
  [ProviderType.CAPTURE]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Capture'
    ),
  },
  [ProviderType.CLOUDINARY]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Cloudinary'
    ),
  },
  [ProviderType.SCOREPLAY]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'ScorePlay'
    ),
  },
  [ProviderType.ASSETBANK]: {
    supportsCategories: false,
    requiresAuthorAttribution: false,
    fetchMediaErrorMessage: sprintf(
      /* translators: %s: media provider name. */
      __('Error loading media from %s', 'web-stories'),
      'Asset Bank'
    ),
  },
};

export function is3pMediaProvider(providerCode) {
  return !!PROVIDERS[providerCode];
}
