/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const MEDIA3P_SET_SELECTED_PROVIDER = 'MEDIA3P_SET_SELECTED_PROVIDER';
export const MEDIA3P_RESET_MEDIA = 'MEDIA3P_RESET_MEDIA';
export const MEDIA3P_SET_INACTIVE = 'MEDIA3P_SET_INACTIVE';
export const MEDIA3P_SET_SEARCH_TERM = 'MEDIA3P_SET_SEARCH_TERM';
export const MEDIA3P_UPDATE_MEDIA_DIMENSIONS =
  'MEDIA3P_UPDATE_MEDIA_DIMENSIONS';
