/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback } from '@web-stories-wp/react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { Headline, THEME_CONSTANTS } from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import PaginatedMediaGallery from '../common/paginatedMediaGallery';
import useMedia from '../../../../../app/media/useMedia';
import { PROVIDERS } from '../../../../../app/media/media3p/providerConfiguration';
import { ChipGroup } from '../../shared';
import { useInsertLibraryMediaElement } from '@src/hooks';
import ExternalMediaList from '../externalMediaPane/components/externalMediaList';
import { map3pToExternalMedia } from '../common/mediaDataMapping';
import { getResourceWithDimensions } from '../../../../../../../../utils/checkMediaDimensions';

const MediaSubheading = styled(Headline).attrs(() => ({
  as: 'h2',
  size: THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.XX_SMALL,
}))`
  margin-top: 24px;
  padding: 0 24px;
  ${(props) => props.shouldDisplay || 'display: none;'}
`;

const ProviderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100px;
  max-width: 100%;
  top: 0;
  left: 0;
  ${({ hidden }) =>
    hidden &&
    css`
      position: absolute;
      visibility: hidden;
    `}
`;

function ProviderPanel({
  providerType,
  isActive,
  searchTerm,
  layoutType,
  enableDeletion,
  enableEditInAzzuu,
  ...rest
}) {
  const { insertResource } = useInsertLibraryMediaElement();

  const { media3p } = useMedia(({ media3p }) => ({ media3p }));

  if (!isActive) {
    return <ProviderWrapper {...rest} hidden />;
  }

  const state = media3p[providerType].state;
  const actions = media3p[providerType].actions;
  const displayName = state.categories.selectedCategoryId
    ? state.categories.categories.find(
        (e) => e.id === state.categories.selectedCategoryId
      ).label
    : __('Trending', 'web-stories');

  // We display the media name if there's media to display or a category has
  // been selected.
  const shouldDisplayMediaSubheading = Boolean(
    state.media?.length || state.categories.selectedCategoryId
  );

  const isGallery = layoutType === 'grid';

  const onDimensionsUpdate = useCallback(
    (id, width, height) => {
      actions.updateMediaDimensions({
        id,
        width,
        height,
      });
    },
    [actions.updateMediaDimensions]
  );

  const onInsert = useCallback(
    (resource, mediaEl, thumbnailUrl) => {
      if (resource.width > 0 && resource.height > 0) {
        insertResource(resource, thumbnailUrl);
        return;
      }

      getResourceWithDimensions(resource, mediaEl)
        .then((resourceWithDimensions) => {
          onDimensionsUpdate(
            resource.id,
            resourceWithDimensions.width,
            resourceWithDimensions.height
          );
          insertResource(resourceWithDimensions, thumbnailUrl);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [insertResource, onDimensionsUpdate]
  );

  return (
    <ProviderWrapper {...rest}>
      {PROVIDERS[providerType].supportsCategories && (
        <ChipGroup
          items={state.categories.categories}
          selectedItemId={state.categories.selectedCategoryId}
          selectItem={actions.selectCategory}
          deselectItem={actions.deselectCategory}
        />
      )}
      <MediaSubheading
        data-testid={'media-subheading'}
        shouldDisplay={shouldDisplayMediaSubheading}
      >
        {displayName}
      </MediaSubheading>
      {isGallery ? (
        <PaginatedMediaGallery
          providerType={providerType}
          resources={state.media}
          isMediaLoading={state.isMediaLoading}
          isMediaLoaded={state.isMediaLoaded}
          hasMore={state.hasMore}
          setNextPage={actions.setNextPage}
          onDimensionsUpdate={onDimensionsUpdate}
          onInsert={onInsert}
          searchTerm={searchTerm}
          selectedCategoryId={state.categories.selectedCategoryId}
          hasError={state.hasError}
          enableDeletion={enableDeletion}
          enableEditInAzzuu={enableEditInAzzuu}
        />
      ) : (
        <ExternalMediaList
          assets={state.media.map((asset) =>
            map3pToExternalMedia({
              ...asset,
              enableDeletion,
              enableEditInAzzuu,
            })
          )}
          loadAssets={actions.setNextPage}
          isLoading={state.isMediaLoading}
          hasMore={state.hasMore}
          hasError={state.hasError}
          enableDeletion={enableDeletion}
          enableEditInAzzuu={enableEditInAzzuu}
        />
      )}
    </ProviderWrapper>
  );
}

ProviderPanel.propTypes = {
  providerType: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string,
};

export default ProviderPanel;
