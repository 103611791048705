import type {
  Resource,
  ResourceId,
} from '@src/web-stories-wp/media/src/resource';

type Dimensions = { width: number; height: number; isVideoPoster?: boolean };

export const savedResourcesDimensions = {
  list: {},
  get: function (id: ResourceId) {
    return this.list[id];
  },
  set: function (id: ResourceId, dimensions: Dimensions) {
    this.list[id] = dimensions;
  },
};

export function getUpdatedResourceWithDimensions(
  resource: Resource,
  dimensions: Dimensions
): Resource {
  let sizes = resource.sizes;

  Object.keys(resource.sizes).forEach((key) => {
    const existingSize = resource.sizes[key];

    if (existingSize.width > 0 && existingSize.height > 0) {
      return;
    }

    sizes[key].width = dimensions.width;
    sizes[key].height = dimensions.height;
  });

  return { ...resource, ...dimensions, sizes };
}

export async function getResourceWithDimensions(
  resource: Resource,
  mediaEl: HTMLVideoElement | HTMLImageElement
): Promise<Resource> {
  if (resource.width > 0 && resource.height > 0) {
    return Promise.resolve(resource);
  }

  return new Promise((resolve, reject) => {
    let dimensions = savedResourcesDimensions.get(resource.id);

    if (dimensions && dimensions.width > 0 && dimensions.height > 0) {
      const resourceWithDimensions = getUpdatedResourceWithDimensions(
        resource,
        dimensions
      );
      return resolve(resourceWithDimensions);
    }

    const isVideo = mediaEl instanceof HTMLVideoElement;

    dimensions = isVideo
      ? { width: mediaEl.videoWidth, height: mediaEl.videoHeight }
      : { width: mediaEl.naturalWidth, height: mediaEl.naturalHeight };

    if (dimensions.width > 0 && dimensions.height > 0) {
      const resourceWithDimensions = getUpdatedResourceWithDimensions(
        resource,
        dimensions
      );

      savedResourcesDimensions.set(resource.id, dimensions);
      return resolve(resourceWithDimensions);
    }

    if (isVideo) {
      mediaEl.onloadedmetadata = () => {
        dimensions = {
          width: mediaEl.videoWidth,
          height: mediaEl.videoHeight,
        };

        const resourceWithDimensions = getUpdatedResourceWithDimensions(
          resource,
          dimensions
        );

        savedResourcesDimensions.set(resource.id, dimensions);
        return resolve(resourceWithDimensions);
      };

      mediaEl.onerror = () => {
        return reject(
          new Error(`Couldn't load dimensions for image ${resource.id}`)
        );
      };
    } else {
      mediaEl.onload = function () {
        dimensions = {
          width: mediaEl.naturalWidth,
          height: mediaEl.naturalHeight,
        };

        const resourceWithDimensions = getUpdatedResourceWithDimensions(
          resource,
          dimensions
        );

        savedResourcesDimensions.set(resource.id, dimensions);
        return resolve(resourceWithDimensions);
      };

      mediaEl.onerror = () => {
        return reject(
          new Error(`Couldn't load dimensions for image ${resource.id}`)
        );
      };
    }
  });
}
